<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <template v-if="(item || {})?.not_living">
            <v-col cols="12" md="12">
              <v-text-field type="text" hide-details filled label="Найменування власника/орендаря нежитлового приміщення" v-model="first_name"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="first_name ? '' : 'req-star'"
                            color="grey"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" md="4">
              <v-text-field type="text" hide-details filled label="Прізвище" v-model="first_name"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="first_name ? '' : 'req-star'"
                            color="grey"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text" hide-details filled label="Ім’я" v-model="middle_name"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="middle_name ? '' : 'req-star'"
                            color="grey"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field type="text" hide-details filled label="По-батькові" v-model="last_name"
                            required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :class="last_name ? '' : 'req-star'"
                            color="grey"
              />
            </v-col>
          </template>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Телефон" v-model="phone_number" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Email" v-model="email" color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="relation_type_select" hide-details filled label="Відношення" v-model="relation_id"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="relation_id ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="registrationTypeSelect" hide-details filled label="Вид реєстрації" v-model="registration_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="registration_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_start" req label="Дата початку" :class_="date_start ? '' : 'req-star'"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_end" label="Дата закінчення"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_of_birth" label="Дата народження"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Ідентифікаційни код" v-model="ipn"
                          color="grey"
                          :class="ipn.length > 12 ? 'req-star' : ''"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Паспорт" v-model="passport"
                          color="grey"
                          :class="passport.length > 30 ? 'req-star' : ''"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Свідоцтво про народж." v-model="birth_certificate"
                          color="grey"
                          :class="birth_certificate.length > 40 ? 'req-star' : ''"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createMember" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import {registrationTypeSelect} from "@/utils/icons";
import {CREATE_FAMILY_MEMBER, UPDATE_FAMILY_MEMBER, DELETE_FAMILY_MEMBER} from "@/store/actions/family";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'family_member_modal_delete'

export default {
  name: "HWP_Modal_Family",
  mixins: [ModalComponentMixin],
  computed: {
    ...mapGetters({
      relation_type_select: 'getRelationTypeSelect',
    }),
    isNew() {
      return this.item.person_hash === undefined
    }
  },
  data() {
    return {
      registrationTypeSelect,
      flat_hash: this.item.flat_hash || '',
      person_hash: this.item.person_hash || '',
      first_name: this.item.first_name || '',
      middle_name: this.item.middle_name || '',
      last_name: this.item.last_name || '',
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      relation_id: this.item.family_relation_id || 0,
      registration_type: this.item.registration_type || '',
      phone_number: this.item.phone_number ? this.item.phone_number : '',
      phone_number_id: this.item.phone_number_id ? this.item.phone_number_id : 0,
      email: this.item.email ? this.item.email : '',
      email_id: this.item.email_id ? this.item.email_id : 0,
      date_of_birth: this.item.date_of_birth || '',
      ipn: this.item.ipn || '',
      passport: this.item.passport || '',
      birth_certificate: this.item.birth_certificate || '',
      personal: this.item.personal || false
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.flat_hash = this.item.flat_hash || ''
      this.person_hash = this.item.person_hash || ''
      this.first_name = this.item.first_name || ''
      this.middle_name = this.item.middle_name || ''
      this.last_name = this.item.last_name || ''
      this.date_start = this.item.date_start || ''
      this.date_end = this.item.date_end || ''
      this.relation_id = this.item.family_relation_id || 0
      this.registration_type = this.item.registration_type || ''
      this.phone_number =  this.item.phone_number ? this.item.phone_number : ''
      this.phone_number_id = this.item.phone_number_id ? this.item.phone_number_id : 0
      this.email = this.item.email ? this.item.email : ''
      this.email_id = this.item.email_id ? this.item.email_id : 0
      this.date_of_birth = this.item.date_of_birth || ''
      this.ipn = this.item.ipn || ''
      this.passport = this.item.passport || ''
      this.birth_certificate = this.item.birth_certificate || ''
      this.personal = this.item.personal || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення мешканця: ${this.first_name} ${this.last_name} ${this.middle_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createMember() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Прізвище, ім’я, по-батькові, відношення, тип реєстрації та дата початку - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      if (this.passport.length > 30) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Поле паспорт має бути менше 31 символів',
          color: 'error lighten-1'
        })
        return
      }

      if (this.ipn.length > 12) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Поле ідентифікаційний код має бути менше 13 символів',
          color: 'error lighten-1'
        })
        return
      }

      if (this.birth_certificate.length > 40) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Поле свідоцтво про народження має бути менше 41 символів',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        flat_hash: this.flat_hash,
        person_hash: this.person_hash,
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        date_start: this.date_start ? this.date_start : null,
        date_end: this.date_end ? this.date_end : null,
        family_relation_id: this.relation_id,
        registration_type: this.registration_type,
        ipn: this.ipn,
        passport: this.passport,
        date_of_birth: this.date_of_birth || null,
        birth_certificate: this.birth_certificate
      }

      payload.phone_number = {
        contact_value: this.phone_number ? this.phone_number : null,
        contact_type: 'phone',
        id: this.phone_number_id ? this.phone_number_id : null
      }

      payload.email = {
        contact_value: this.email ? this.email : null,
        contact_type: 'email',
        id: this.email_id ? this.email_id : null
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_FAMILY_MEMBER, payload)
            .then((res) => {
              if (res) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_FAMILY_MEMBER, payload)
            .then((res) => {
              if (res) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.flat_hash = payload.flat_hash || ''
              this.person_hash = payload.person_hash || ''
              this.first_name = payload.first_name || ''
              this.middle_name = payload.middle_name || ''
              this.last_name = payload.last_name || ''
              this.date_start = payload.date_start || ''
              this.date_end = payload.date_end || ''
              this.relation_id = payload.family_relation_id || 0
              this.registration_type = payload.registration_type || ''
              this.phone_number =  payload.phone_number ? payload.phone_number : ''
              this.phone_number_id = payload.phone_number_id ? payload.phone_number_id : 0
              this.email = payload.email ? payload.email : ''
              this.email_id = payload.email_id ? payload.email_id : 0
              this.date_of_birth = payload.date_of_birth || ''
              this.ipn = payload.ipn || ''
              this.passport = payload.passport || ''
              this.birth_certificate = payload.birth_certificate || ''
              this.personal = payload.personal || false

              if (this.item.not_living && !this.isNew) {
                this.first_name = `${this.first_name}${this.middle_name ? ` ${this.middle_name}` : ""}${this.last_name ? ` ${this.last_name}` : ""}`
                this.middle_name = this.middle_name ? this.middle_name : ''
                this.last_name = this.last_name ? this.last_name : ''
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_FAMILY_MEMBER, { person_hash: this.person_hash, flat_hash: this.flat_hash })
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
